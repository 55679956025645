import * as React from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Row from "../../../components/row"

const VideoLibrary = () => ( 
  <Layout>
	<Helmet>
        <script src={"/js/main.js"} type="text/javascript" />
	    <script src={"/js/v-library.js"} type="text/javascript" />
    </Helmet>
    <Seo title="Video Library | IBD Perspectives" description="A selection of short videos focused on areas of interest in IBD" />
    <header className="headline">
	<img src={"/images/black-and-green-ibd-science-graphic-image.svg"} alt="ibd science gastroenterology" />
	</header>
	<section className="wrap"><h2 className="perfectSpace">Curated and continually updated content to advance the science and treatment of IBD</h2><br/>
	<header className="orange rule"><h1 id="video-library">VIDEO LIBRARY: <span>IBD PERSPECTIVES</span></h1></header>
	<p class="no-margin-on-mobile">A selection of short videos focused on areas of interest in IBD</p>
		<div className="videoHolder"><iframe id="video-player" src="https://player.vimeo.com/video/668656817?h=d94f329a43" width="1032" height="590" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div>
		<div className="thumbnailHolder"><div className="smartThumb selected"><h5>Patient Case Vignette</h5><p>Proactive disease monitoring informs progression to advanced therapy
</p><span>Dr Séverine Vermeire</span></div><div className="smartThumb"><h5>Patient Case Vignette</h5><p>Tailoring treatment selection to meet objective measures of disease control and outstanding patient needs</p>
  <span>Dr David T. Rubin </span></div><div className="smartThumb"><h5>Unmet Patient Need</h5>
    <p>Unmet needs of patients despite conventional therapies for UC</p>
    <span>Dr Séverine Vermeire</span></div><div className="smartThumb"><h5>Master Class</h5>
      <p>Understanding potential clinical implications of mechanisms of action for UC therapies </p>
      <span>Dr Raja Atreya </span></div></div>
	</section>
    <section className="spacer end"></section>
	<footer className="definitions">IBD=Inflammatory bowel disease; UC=ulcerative colitis. </footer>
  </Layout>
)

export default VideoLibrary
